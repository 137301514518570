import { useEffect, useState } from "react";
import axios from "axios";
import './App.css';
import FirstSection from "./components/FirstSection";
import SideBySide from "./components/SideBySide";

function App() {
  const [pageData, setPageData] = useState(null);

  const fetchPage = async () => {
    try {
      const response = await axios.get('https://griddesign.co.uk/wp-json/wp/v2/pages/32');
      console.log('page data', response.data.acf.blocks);
      setPageData(response.data.acf.blocks);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchPage();
  }, []);

  if (!pageData) {
    return (
      <p>Loading...</p>
    );
  }

  return (
    <div>
      <div className="h-20 flex justify-end gap-4 px-10">
        <p>Some nav stuff</p>
      </div>
      {pageData && pageData.map((item, idx) => {
        if (item.acf_fc_layout === 'first_section') {
          return (
            <FirstSection
              key={`${item.acf_fc_layout} ${idx}`}
              {...item}
            />
          )
        }
        if (item.acf_fc_layout === 'side_by_side') {
          return (
            <SideBySide
              key={`${item.acf_fc_layout} ${idx}`}
              {...item}
            />
          )
        }
      })}
    </div>
  );
}

export default App;

