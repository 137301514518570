export default function FirstSection({ subtitle, first_section_links, ticker, title_logo }) {
  return (
    <div>
      <p>{ticker[0].title}</p>
      <img src={title_logo.url} alt={title_logo.alt} />
      <p>{subtitle}</p>
      {first_section_links && first_section_links.map((link) => (
        <a href={link.link.url} key={link.link.url}>{link.link.title}</a>
      ))}
    </div>
  )
}
